<template>
    <div>
      <!-- <div id="back">
        <a href="/user">back</a>
      </div> -->
    <div >
        
       <agreezone/>
    </div>
    </div>
    </template>
    
    <script>
    import agreezone from "./softwareprotocal.vue"
    export default {
      name: 'privicy',
      props: {
        msg: String
      }
    ,  components:{
    // Taskszone,
    agreezone
  },
    }
    </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
    #back{
      position: absolute;;
      left: 10%;
    }
    #reference{
      font-family: fangsong;
      text-align: left;
      position:absolute;
      left: 40%;
    }
    </style>
    