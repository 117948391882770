<template>
    <div class="content">
        <div >
            <div class="terms">
            <div class="section">
                    <h3>服务条款的确认和接纳</h3>
                    <p>本软件的所有权和运作权归寰宇构象（成都）软件技术有限公司所有。在本服务条款中简称 “本软件公司”及有时称为“我们”。使用本软件产品及服务将视为您同意以下使用条款。</p>
                </div>
            <div class="section">
                    <h3>1、仅供个人使用</h3>
                    <p>本软件仅供用户个人用于非商业用途。如果要销售本软件或者与本软件有关或派生的任何资料、服务或软件，则必须得到本软件公司的允许。如果用户对本软件有任何意见，或有如何改进的建议，可向我们提出。请注意，如果这样做，还会授予本软件和第三方在本软件（或第三方软件）中无偿使用和加入用户的建议或意见的权利。
                    </p>
                </div>
            <div class="section">
                    <h3>2、知识产权</h3>
                    <p>用户承认本软件公司拥有对本软件的所有权利，包括但不限于所有知识产权。“知识产权”指在专利法、版权法、商业机密法、商标法、反不正当竞争法等法中规定的所有权利以及其中的所有应用、更新、扩展和恢复，无论在现在还是以后在全球范围内实施和生效。用户同意不会修改、改编、翻译本软件、创作本软件的派生作品、通过反编译、反向工程、反汇编或其它方式从本软件得到源代码。用户同意不会删除、掩盖或更改本软件公司或任何第三方的版权声明、商标或其它所有权声明。
                    </p>
            </div>
            <div class="section">
                    <h3>3、用户隐私制度</h3>
                    <p>尊重用户个人隐私是本软件公司的一项基本政策。所以，本软件公司一般不会公开、编辑或透露用户的注册资料或保存在本软件公司服务中的非公开内容，除非本软件公司在诚信的基础上认为透露这些信息在以下几种情况是必要的：
                    </p>
                    <ul>
                        <li>遵守有关法律规定，包括在国家有关机关查询时，提供用户通过本软件发布的信息内容及其发布时间、互联网地址或者域名。</li>
                        <li>遵从本软件的服务程序。</li>
                        <li>保持维护本软件公司的商标等知识产权。</li>
                        <li>在紧急情况下竭力维护用户个人和社会大众的隐私安全。</li>
                        <li>本软件公司认为必要的其他情况下。</li>
                    </ul>
                    <p>要了解本软件数据保护措施的具体信息，请阅读本软件的隐私权政策，网址是：https://www.cosmosatom.cc/privicy。该政策解释了当您使用本软件的相关的服务时，本软件会如何处理您的个人信息，以及如何保护您的隐私权。您同意会在遵守本软件隐私权政策的前提下应用自己的数据。</p>
            </div>
            <div class="section">
                <h3>4、服务变更</h3>
                <p>本软件公司保留随时变更、中断或终止服务而不需通知用户的权利。用户接受本软件公司行使变更、中断或终止服务的权利，本软件公司不需对用户或第三方负责。</p>
            </div>
            <div class="section">
                <h3>5、责任限制</h3>
                <p>用户理解并同意自主选择下载和使用本软件和所有与本软件一同提供或通过本软件提供的第三方软件，风险自负，并且对于因下载或使用本软件和此类第三方软件而对计算机系统造成的损坏或数据的丢失，用户应承担全部责任。在适用法律允许的最大范围内，本软件公司明确表示不提供任何其他类型的保证，不论使明示的或默示的，包括但不限于适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何默示保证和责任。
                </p>
                <p>在适用法律允许的最大范围内，本软件公司及其许可人不会向您做出以下声明或担保：</p>
                <ul>
                    <li>您对服务的使用会满足您的需求；</li>
                    <li>您对服务的使用会连续无中断、及时、安全或没有错误；</li>
                    <li>您使用相关服务而获得的任何信息一律准确可靠；</li>
                    <li>作为相关服务的一部分向您提供的任何软件所发生的操作或功能瑕疵将获得修正。</li>
                </ul>
                <p>在适用法律允许的最大范围内，本软件公司不就因用户使用本软件引起的，或在任何方面与本软件公司的产品和服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。用户上载的行为，即意味着用户或用户代理的著作权人授权本软件公司及其关联公司在本软件的服务器上保存内容，但用户或原著作权人仍保有上载作品的著作权，且本软件公司未经用户或原著作权人许可不得将上载作品在其他公开渠道发布。如用户在使用本软件时涉及到其他本软件公司或其关联公司提供的服务，还应遵守相应的服务条款。
                </p>
            </div>
            <div class="section">
                <h3>6、保障</h3>
                <p>用户同意保障和维护本软件公司的利益，负责支付由用户违反本服务条款或本软件公司其他服务条款而引起的律师费用、损害补偿费用、政府机关处罚费用和其它侵权赔偿费用等。</p>
            </div>
            <div class="section">
                <h3>7、法律</h3>
                <p>上述条款将受中华人民共和国法律的约束并依据其解释。如出现纠纷，用户和本软件公司一致同意将纠纷交由当地人民法院管辖。如用户对本服务条款内容有任何疑问，可联系本公司获取相关信息。</p>
            </div>
        </div>
    </div>

</div></template>
    
<script>
export default {
    name: 'agreezone',
    props: {
        msg: String
    }
}
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.section{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
   
}
p{
    text-align: left;
}
ul {
    display: block;
    
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    text-align: left;
    li {
        
        list-style: none;
        display: list-item;
    text-align: -webkit-match-parent;
    }
}
.terms{

    display: flex;
    flex-direction:column;
}
</style>
    